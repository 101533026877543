/* eslint-disable */
import React, { useState, useEffect } from 'react';
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
// import {
//   // isEmpty,
//   throttle,
// } from 'lodash';

import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Badge,
} from '@material-ui/core/';
import { Switch } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import userContainsWord from '../../utils/userContainsWord';
import ReservationsContext from './ReservationsContext/ReservationsContext';
import TableErrors from '../../components/TableErrors';
import * as ReservationsApi from '../../api/restaurants/reservations';
import prepareReservationsTabs from '../../utils/prepareReservationsTabs';
import prepareReservationsTable from '../../utils/prepareReservationsTable';
// import prepareTablePaginator from '../../utils/prepareTablePaginator';
import isTruthy from '../../utils/isTruthy';
import TheMenu from '../../components/TheMenu';
import ReservationsTabs from '../../components/ReservationsTabs';
import ReservationsTable from '../../components/ReservationsTable';
import ReservationsAccordion from '../../components/ReservationsAccordion';
import ReservationsDatepicker from '../../components/ReservationsDatepicker';
import DialogBlockDay from '../../components/DialogBlockDay';
import DialogBlockPardepan from '../../components/DialogBlockPardepan';

import DialogTotal from '../../components/DialogTotal';
import DialogTotal2 from '../../components/DialogTotal2';

import DialogFilterStatus from '../../components/DialogFilterStatus';
import DialogUnBlockDay from '../../components/DialogUnBlockDay';
import DialogUnBlockPardepan from '../../components/DialogUnBlockPardepan';
import 'antd/dist/antd.css';
import './Reservations.scss';
import ReactExport from "react-data-export";
import { checkPropTypes } from 'prop-types';
import { consoleSandbox } from '@sentry/utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const currentCallConfig = () => {
  const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
  const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
  if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
  const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
  const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
  const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
  const config = {
    lastPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
    currentPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
    perPage: retrieveReservationsConfig !== null
      ? Number(JSON.parse(retrieveReservationsConfig).perPage)
      : 100,
    total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
    from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
    to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
    options: [5, 25, 100],
    date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
    zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
    sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
    sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
    search: '',
  };
  return config;
};

const Reservations = () => {
  // const throttleTime = 200;
  const [state, setState] = useState({
    loading: true,
    error: null,
    reservations: [],
    pardepanBlocked: [],
    reservationsBlocked: { reservations: [], cancelled: [] },
    capacity: [],
    paginator: {},
    currentFilter: 'Reservas Confirmadas',
    reservationsVisible: true,
  });
  const [dialogBlockday, setDialogBlockDay] = useState(false);
  const [dialogBlockPardepan, setDialogBlockPardepan] = useState(false);
  const [dialogUnBlockPardepan, setDialogUnBlockPardepan] = useState(false);

  const [dialogUnBlockday, setDialogUnBlockDay] = useState(false);
  const [exportDataDay, setExportDataDay] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [exportDataNight, setExportDataNight] = useState([]);
  const [showingWaitingList, setShowingWaitingList] = useState(false);
  const [showingDeletedList, setShowingDeletedList] = useState(false);
  const [FilterStatusDialog, setDialogFilterStatus] = useState(false);
  // const [reservations, setReservations] = useState(state.reservations);
  const [title, setTitle] = useState('Reservas Confirmadas');
  // sets the number of pending reservations in the month to 0
  const [pending, setPending] = useState(0);
  const [automatic, setAutomatic] = useState(0);
  const [filterdReservations, setFilteredReservations] = useState(state.reservations);
  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());
  const [reload, setReload] = useState(false);
  const [totalDialog, setTotalDialog] = useState(false);
  const [totalDialog2, setTotalDialog2] = useState(false);

  const [totalDialogData, setTotalDialogData] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });
  const [totalDialogData2, setTotalDialogData2] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });

  const [activeIndex, setActiveIndex] = React.useState(0);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const openTotalDialog = () => {
    setTotalDialog(true);
  };
  const closeTotalDialog = () => {
    setTotalDialog(false);
  };
  const openTotalDialog2 = () => {
    setTotalDialog2(true);
  };
  const closeTotalDialog2 = () => {
    setTotalDialog2(false);
  };
  const openFilterDialog = () => {
    setDialogFilterStatus(true);
  };
  const closeFilterDialog = () => {
    setDialogFilterStatus(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopper = Boolean(anchorEl);
  

  const zones = Array.from(new Set(exportData.map(data => data[2])));

  const campos = zones.map(zone => ({
    columns: [
      { title: zone, width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FF000000" } } } },//pixels width 
      { title: "", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
    ],
    data: exportData.filter(data => data[2] == zone)
  }));

  var multiDataSet2 = [
    {
      columns: [
        { title: "NOMBRE", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "MESA", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ZONA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "FECHA", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "HORA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ASISTENTES", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "COMENTARIOS", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width
        { title: "CONTACTO", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "CATEGORÍAS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ESTATUS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width  
      ], data: [

      ]
    },
    ...campos
  ];

  // var multiDataSet2 = [
  //   {
  //     columns: [
  //       { title: "NOMBRE", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "MESA", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "ZONA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "FECHA", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "HORA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "ASISTENTES", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "COMENTARIOS", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width
  //       { title: "CONTACTO", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "CATEGORÍAS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "ESTATUS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width  
  //     ], data: [

  //     ]
  //   },
  //   {
  //     columns: [
  //       { title: "DÍA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FF000000" } } } },//pixels width 
  //       { title: "", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //     ],
  //     data: exportDataDay

  //   }, {
  //     columns: [
  //       { title: "NOCHE", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFE6E6E6" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FF000000" } } } },//pixels width 
  //       { title: "", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
  //       { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 

  //     ],
  //     data: exportDataNight
  //   }
  // ];
  const [nameForFilter, setNameForFilter] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment(new Date(), 'YYYY-MM-DD').locale('es').format('DD MMMM YYYY'));

  const openDialogBlockDay = () => {
    setDialogBlockDay(true);
  };
  const closeDialogBlockDay = () => {
    setDialogBlockDay(false);
  };

  const openDialogUnBlockDay = () => {
    setDialogUnBlockDay(true);
  };
  const closeDialogUnBlockDay = () => {
    setDialogUnBlockDay(false);
  };

  const openDialogBlockPardepan = () => {
    setDialogBlockPardepan(true);
  };
  const closeDialogBlockPardepan = () => {
    setDialogBlockPardepan(false);
  };

  const openDialogUnBlockPardepan = () => {
    setDialogUnBlockPardepan(true);
  };
  const closeDialogUnBlockPardepan = () => {
    setDialogUnBlockPardepan(false);
  };
  const switchHandler = (value) => {
    if (value) {
      openDialogBlockDay(true);
    } else {
      openDialogUnBlockDay(true);
    }
  };
  const switchHandler2 = (value) => {
    if (value) {
      openDialogBlockPardepan(true);
    } else {
      openDialogUnBlockPardepan(true);
    }
  };
  const [isFilteringByName, setIsFilteringByName] = useState({
    isFiltering: false,
    filterCount: 0,
    currentPage: 1,
  });
  const [isFilteringByStatus, setIsFilteringByStatus] = useState({
    isFiltering: false,
    booleans: [],
  });

  // const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;


  const scroll = (scrollOffset) => {
    const div = document.getElementById('btnscroller');
    div.scrollLeft += scrollOffset;
  };

  const handlePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );
  const reloadpage = () => {
    setReload(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => reloadpage(), 5 * 60 * 1000);
    //return () => clearTimeout(timer);
  }, []);
  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };
  const renderNoResults = () => {
    const errorData = {
      image: 'noresults',
      message: 'NO HAY RESERVAS PARA EL EL FILTRO QUE SELECCIONÓ',
      message2: 'Por favor, dele clic a “Filtrar por Estatus” y seleccione otro filtro.'
    };
    return (
      <TableErrors data={errorData} />
    );
  };
  const renderBadge = () => {
    const count = state.reservations.filter(res => (res.type === "waitinglist" || res.type === "pardepan") && res.status === "LISTA DE ESPERA").length;
    if (count > 0) {
      return (
        <Badge badgeContent={count} color="primary" >
          <Button onClick={displayWaitingList} className="btnblanco circleRadius" variant="contained" color="primary">
            <span class="textblack">PENDIENTE POR CONTESTAR </span>
          </Button>
        </Badge>
      );
    } else {
      return (
        <Button onClick={displayWaitingList} className="btnblanco circleRadius" variant="contained" color="primary">
          <span class="textblack">PENDIENTE POR CONTESTAR </span>
        </Button>
      );
    }
  };
  const renderBadge2 = () => {
    const count = pending;
    if (count > 0) {
      return (
        <Badge badgeContent={count} color="primary" >
          <Badge badgeContent={automatic} color="secondary" />
          <IconButton
            onClick={() => { setIsCalendarOpen(true) }}
            edge="start"
            color="primary"
            aria-label="menu"
            size="small"
          >
            <span>
              {' CALENDARIO '}
            </span>
          </IconButton>
        </Badge>
      );
    } else {
      return (
        <>
          <Badge badgeContent={automatic} color="secondary" >
          <IconButton
            onClick={() => { setIsCalendarOpen(true) }}
            edge="start"
            color="primary"
            aria-label="menu"
            size="small"
          >
            <span>
              {' CALENDARIO'}
            </span>
          </IconButton>
        </Badge >
      </>
      );
    }
  };
const renderNoPending = () => {
  const errorData = {
    image: 'noresults',
    message: 'NO HAY RESERVAS PENDIENTES PARA EL FILTRO QUE SELECCIONÓ ',
    message2: ''
  };
  return (
    <TableErrors data={errorData} />
  );
};
const renderNoReservation = () => {
  const errorData = {
    message: 'Aún no tienes ninguna reservación.',
    action: () => navigate('/crear-reservacion'),
    buttonText: 'NUEVA RESERVACIÓN',
    image: 'reservations',
  };
  return (
    <TableErrors data={errorData} />
  );
};
const renderReservationBlocked = () => {

  const errorData = {
    image: 'reservationsblocked',
    name: state.reservationsBlocked.reservations[0].name,
    reason: state.reservationsBlocked.reservations[0].reason,
    email: state.reservationsBlocked.reservations[0].email,
    phone: state.reservationsBlocked.reservations[0].phone,
    people: state.reservationsBlocked.reservations[0].people,
    checkIn: state.reservationsBlocked.reservations[0].checkIn,
    checkOut: state.reservationsBlocked.reservations[0].checkOut,
  };
  return (
    <TableErrors data={errorData} />
  );
};

useEffect(() => {
  if (reload) {
    setReload(false);
  }
  const fetchReservations = async () => {
    const newConfig = currentCallConfig();
    setSelectedDate(moment(currentCallConfig().date).locale('es').format('YYYY-MM-DD'));

    if (isFilteringByName.isFiltering && isFilteringByName.filterCount === 0) {
      newConfig.search = nameForFilter;
      newConfig.currentPage = isFilteringByName.currentPage;
    }
    if (isFilteringByName.isFiltering && isFilteringByName.filterCount > 0) {
      newConfig.search = nameForFilter;
    }
    try {
      //const resp = await ReservationsApi.getTotalReservationsPerMonth(moment(currentCallConfig().date).format('YYYY'), moment(currentCallConfig().date).format('MM'));
      //   const aux = await ReservationsApi.getAllDayReservations(newConfig);
      let { success, data } = await ReservationsApi
        .getReservationsFast(newConfig);
      if (isTruthy(success)) {
        setPending(data.total);
        setAutomatic(data.countAutomatic);
        console.log(data);
        await prepareReservationsTable(data.reservations.data).then((tableData) => {
          // setReservations(tableData);
          setFilteredReservations(tableData.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA")));
          const newCallConfig = {
            currentPage: data.currentPage,
            from: data.from,
            perPage: data.perPage,
            lastPage: data.lastPage,
            to: data.to,
            total: data.total,
          };
          setState({
            loading: false,
            error: null,
            reservations: tableData,
            pardepanBlocked: (data.pardepanBlocked !== null) ? data.pardepanBlocked : [],
            reservationsBlocked: (data.allDay !== null) ? data.allDay : { reservations: [] },
            reservationsVisible: data.visible,
            capacity: prepareReservationsTabs(data.reservations.capacity),
            paginator: newCallConfig,
            currentFilter: 'Reservas confirmadas'
          });
          //CONTACTO	CATEGORIA	NOMBRE	MESA	ZONA	HORA	ASISTENTES	COMENTARIOS
          // const datareservations = tableData.map(elt => [ (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.name, elt.tableNumber,elt.table,elt.date,elt.time,elt.people,elt.status,elt.comments]);
          //const dataday= datareservations.filter(person => person < 60);
          //setExportDataDay(dataday);
          //console.log(dataday)
          // eslint-disable-next-line no-param-reassign
          const emptydata = [
            [
              { value: "SIN RESERVAS", style: { font: { sz: "24", bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
            ]
          ];
          const dia = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') >= moment(person.time, 'hh:mm A')));
          const dayreservations = dia.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
          const reservationsAll = tableData.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
          if (dia.length > 0) {
            setExportDataDay(dayreservations);
            setExportData(reservationsAll);
          } else {
            setExportDataDay(emptydata);
          }
          const noche = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') < moment(person.time, 'hh:mm A')));
          const nightreservations = noche.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
          if (noche.length > 0) {
            setExportDataNight(nightreservations);
            setExportData(reservationsAll);
          } else {
            setExportDataNight(emptydata);
          }

          //Aquí manejar datos para el excel

          const walkin = tableData.filter(person => person.email === 'client@kokonutstudio.com');

          const asistido = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
          const totales = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
          const result = asistido.reduce((total, currentValue) => total = total + currentValue.people, 0);
          const result2 = walkin.reduce((total, currentValue) => total = total + currentValue.people, 0);
          const result3 = tableData.length;
          const result4 = walkin.reduce((total, currentValue) => total = total + 1, 0);
          setTotalDialogData({
            total: result,
            walkin: result2,
            asistido: result,
          });
          setTotalDialogData2({
            total: result3,
            walkin: result4,
            asistido: result3,
          });
          localStorage.setItem('ReservationsConfig', JSON.stringify(newCallConfig));
        });

      } else {
        setExportDataDay([]);
        setExportData([]);
        setExportDataNight([]);
        setState({
          loading: false, error: data.error, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
        });
      }

    } catch (error) {
      setExportDataDay([]);
      setExportData([]);
      setExportDataNight([]);
      setState({
        loading: false, error: error.message, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
      });
    }
  };

  fetchReservations();
}, [apiCallConfig, reload, nameForFilter, isFilteringByName]);

const prepareMonthData = (data) => {

};
const manageFilterByStatus = (booleans) => {
  if (booleans[5]) {

    //setFilteredReservations(state.reservations);
    resetFilter();

  } else {
    var reservations = [];

    if (booleans[0]) {
      let noshow = state.reservations.filter(res => res.status === "NO SHOW");
      reservations = noshow;
    }
    if (booleans[1]) {
      let canceled = state.reservations.filter(res => res.status === "CANCELADO");
      reservations = canceled;
    }

    if (booleans[2]) {
      let show = state.reservations.filter(res => res.status === "HA ASISTIDO");
      reservations = show;
    }
    if (booleans[3]) {
      let left = state.reservations.filter(res => res.status === "SE HA IDO");
      reservations = left;
    }
    if (booleans[4]) {
      let pending = state.reservations.filter(res => res.status === "EN ESPERA");
      reservations = pending;
    }

    setFilteredReservations(reservations);
  }

}

const manageFilterByName = (value: string) => {
  // Validar si se recibe un string mayor que cero
  // Si si, se comienzan validaciones, si no,
  // se resetean los valores de IsFilteringByName && nameForFilter
  if (value.trim().length > 0) {
    if (value !== nameForFilter) {
      const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
      retrieveReservationsConfig.currentPage = 1;
      localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
      setIsFilteringByName({
        currentPage: 1,
        filterCount: 1,
        isFiltering: true,
      });
    } else {
      // Si filterCount es 0 significa que se llamará el servicio de reservaciones
      // desde la primer página
      if (isFilteringByName.filterCount === 0) {
        const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
        retrieveReservationsConfig.currentPage = 1;
        localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
      }
      setIsFilteringByName({
        ...isFilteringByName,
        isFiltering: true,
        filterCount: isFilteringByName.filterCount + 1,
      });
    }
    setNameForFilter(value);
  } else {
    const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
    retrieveReservationsConfig.currentPage = 1;
    localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
    setIsFilteringByName({
      isFiltering: false,
      filterCount: 0,
      currentPage: 1,
    });
    setNameForFilter('');
  }
};
const displayWaitingList = () => {

  setShowingWaitingList(true);
  const res = state.reservations.filter(reservation => reservation.status === 'RECHAZADO' || reservation.status === 'LISTA DE ESPERA' || reservation.status === 'CANCELADO POR EL CLIENTE' || reservation.status === "ACEPTADO" || (reservation.status === "EN ESPERA" && reservation.type === "waitinglist"));
  setTitle('Pendiente por contestar');
  setFilteredReservations(res);
};
const resetFilter = () => {

  setActiveIndex(3);
  setShowingWaitingList(false);
  setTitle('Reservas Confirmadas');
  const res = state.reservations.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA"));
  setFilteredReservations(res); 

};
const parDePan = () => {

  setActiveIndex(1);
  setShowingWaitingList(true);
  const res = state.reservations.filter(reservation => reservation.type === "pardepan");
  setTitle('Reserva Ya!');
  setFilteredReservations(res);
};
const waitingList = () => {
  setActiveIndex(2);
  setShowingWaitingList(true);
  setTitle('Lista de espera');
  const res = state.reservations.filter(reservation => reservation.type === "waitinglist");
  setFilteredReservations(res);

};
const deletedList = () => {
  setActiveIndex(4);
  setShowingDeletedList(true);
  setTitle('Reservas eliminadas');
  const res = state.reservations.filter(reservation => reservation.status === "ELIMINADO");
  setFilteredReservations(res);

};
const renderDatePicker = () => (
  <ReservationsContext.Provider value={{ apiCallConfig, setApiCallConfig }}>
    <ReservationsDatepicker
      isOpen={isCalendarOpen}
      setIsOpen={setIsCalendarOpen}
      apiCallConfig={apiCallConfig}
      setNewDate={setApiCallConfig}
    />
  </ReservationsContext.Provider>
);
const renderTextBlocked = () => (
  <div>
    <b>Este día fue bloqueado y desbloqueado por el evento: </b>
    <span>{state.reservationsBlocked.cancelled[0].reason}</span>
  </div>
);
const renderTab = () => (
  <Box
    component="div"
    className="w100 dFlex flex-row align-center justify-between"
  >
    <ReservationsContext.Provider value={{ apiCallConfig, state, setApiCallConfig }}>
      <ReservationsTabs />
    </ReservationsContext.Provider>
  </Box>
);
const renderWaitingTabs = () => (
  <>
    <Box
      component="div"
      className="w100"
    >
      <Button type="button" className={activeIndex === 1 ? "active text-blue" : "unactive text-blue"} key={1} onClick={parDePan}>Reserva Ya!</Button>
      <Button type="button" className={activeIndex === 2 ? "active text-pink" : "unactive text-pink"} key={2} onClick={waitingList}>Lista de espera</Button>
      <Button type="button" className={activeIndex === 3 ? "active text-green" : "unactive text-green"} key={3} onClick={resetFilter}>Reservas confirmadas</Button>
    </Box>
  </>
);
const renderTable = () => (
  <>
    <Box
      component="div"
      className="reservations-alerts__box w100 dFlex flex-row align-center justify-between"
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <div
            className="ml-1 mb-1 reservations-alerts__date"
          >
            <Typography
              variant="body2"
            >
              Reservaciones totales del
              {' '}
              <span className="bold capitalize">
                {moment(currentCallConfig().date).locale('es').format('DD MMMM YYYY')}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="reservations-alerts__badge dFlex flex-row align-center justify-between">
            <ReportIcon />
            <Typography
              variant="body2"
              className="pl-1"
            >
              {!state.loading && state.reservationsBlocked.reservations.length === 0 && state.reservationsBlocked.cancelled.length > 0 && renderTextBlocked()}
              El tiempo de vida de cada reservación es de tres horas,
              sin embargo la reservación deberá ser finalizada de forma manual.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
    <Hidden xsDown>
      <Box
        component="div"
        className="reservations-table__box w100 dFlex flex-row align-center justify-between"
      >
        <ReservationsContext.Provider value={
          { apiCallConfig, filterdReservations, setApiCallConfig, title, selectedDate }
        }
        >
          <ReservationsTable setReload={setReload} />
        </ReservationsContext.Provider>
      </Box>
    </Hidden>
    <Hidden smUp>
      <Box
        component="div"
        className="reservations-table__box w100 dFlex flex-row align-center justify-between"
      >
        <ReservationsContext.Provider value={
          { apiCallConfig, filterdReservations, setApiCallConfig, title, selectedDate }
        }
        >
          <ReservationsAccordion />
        </ReservationsContext.Provider>
      </Box>
    </Hidden>
  </>
);

const renderTablePagination = () => (
  <Box
    component="div"
    className="reservations-table__box w100 mb-5 dFlex flex-row flex-row align-center justify-end"
  >
    <ReservationsContext.Provider value={{ apiCallConfig, state, setApiCallConfig }}>
      <TablePaginationCustom context="ReservationsContext" />
    </ReservationsContext.Provider>
  </Box>
);
const renderDialogBlockDay = () => (
  <DialogBlockDay
    isOpen={dialogBlockday}
    onNo={closeDialogBlockDay}
    date={selectedDate}
    setReload={setReload}
  />
);
const renderDialogUnBlockDay = () => (
  <DialogUnBlockDay
    isOpen={dialogUnBlockday}
    onNo={closeDialogUnBlockDay}
    date={selectedDate}
    setReload={setReload}
  />
);
const renderDialogBlockPardepan = () => (
  <DialogBlockPardepan
    isOpen={dialogBlockPardepan}
    onNo={closeDialogBlockPardepan}
    date={selectedDate}
    setReload={setReload}
  />
);

const renderDialogUnBlockPardepan = () => (
  <DialogUnBlockPardepan
    isOpen={dialogUnBlockPardepan}
    onNo={closeDialogUnBlockPardepan}
    date={selectedDate}
    setReload={setReload}
  />
);
const renderSwitch = () => (

  <FormGroup className="flexible flex-end">
    <span className="conmuterbold">BLOQUEAR DÍA</span>
    <FormControlLabel labelPlacement="start" control={<Switch className="switcher" onChange={(value) => { switchHandler(value); }} color="primary" checked={(state.reservationsBlocked !== null) ? state.reservationsBlocked.reservations.length > 0 : false} />} />
  </FormGroup>
);
const renderSwitchVisible = () => (

  <FormGroup className="flexible flex-end mt-10 mb-10">
    <span className="conmuterbold">BLOQUEAR RESERVAS</span>
    <FormControlLabel labelPlacement="start" control={<Switch className="switcher" color="primary" onChange={(value) => { switchHandler2(value); }} checked={(state.pardepanBlocked !== null) ? state.pardepanBlocked.length > 0 : false} />} />
  </FormGroup>
);
return (
  <>
    {!state.loading && renderDialogBlockDay()}
    {!state.loading && renderDialogUnBlockDay()}
    {!state.loading && renderDialogBlockPardepan()}
    {!state.loading && renderDialogUnBlockPardepan()}
    <DialogTotal
      isOpen={totalDialog}
      onNo={closeTotalDialog}
      data={totalDialogData}
    />
    <DialogTotal2
      isOpen={totalDialog2}
      onNo={closeTotalDialog2}
      data={totalDialogData2}
    />
    <DialogFilterStatus
      isOpen={FilterStatusDialog}
      onNo={closeFilterDialog}
      filtering={setIsFilteringByName}
      filterbystatus={manageFilterByStatus}
    />
    <TheMenu />
    {!state.loading && renderDatePicker()}

    <Container className="reservations layout px-0 containerFluid"
    >
      <Box
        component="div"
        className="reservations__header dFlex flex-row align-center flexfix"
      >

        <Grid item xs={12} md={4}>
          <Typography variant="h5" className="reservations__title font__commutter">
            {' '}
            <span className="reservations__title-date botonnegro sm-py2">
              RESERVACIONES
              {' '}
              {moment(currentCallConfig().date, 'YYYY-MM-DD').format('DD MMMM')}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="d-flex sborderhorizontal">
            <Hidden >
              {renderBadge2()}
            </Hidden>
            <div className="w-100 sbordertop">
              <Hidden >
                <IconButton
                  onClick={handlePopper}
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  size="small"
                >
                  BUSCAR
                </IconButton>
              </Hidden>
            </div>
            <div className="w-100 sbordertop">
              <Hidden >
                <IconButton
                  onClick={() => { navigate('floormap'); }}
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  size="small"
                >
                  VER MAPA
                </IconButton>
              </Hidden>
            </div>
            <Popper
              className="reservations__searchBar"
              open={openPopper}
              anchorEl={anchorEl}
              placement="right"
              disablePortal={false}
              transition
              modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: false,
                  boundariesElement: 'scrollParent',
                  hide: false,
                },
              }}

            >
              <Hidden mdUp>
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              </Hidden>
              <TextField
                id="standard-basic"
                placeholder="Buscar"
                onChange={(e) => manageFilterByName(e.target.value)}
                value={nameForFilter}
              />
              <IconButton
                onClick={() => { handlePopper(); manageFilterByName(''); }}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Popper>
          </div>
        </Grid>
        <Grid item xs={12} md="2">
          <div>
            <div className="d-flex sborderhorizontal justify-content-between pr-2">
            </div>
            <div className="d-flex sborderhorizontal justify-content-between reservationsvisible pr-2">
              {!state.loading && renderSwitchVisible()}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3} >
          <Hidden>
            <Button className="btnnegro" onClick={() => { navigate('/crear-reservacion'); }} variant="contained" color="primary">
              NUEVA RESERVACIÓN
            </Button>
          </Hidden>
        </Grid>
      </Box>
      <Box className="bottomButtons" id='btnscroller'>
        {!state.loading && renderBadge()}
        <Button className="btnblanco circleRadius" variant="contained" color="primary" onClick={openTotalDialog}>
          <span class="textblack">TOTAL ASISTIDOS</span>
        </Button>
        <Button className="btnblanco circleRadius" onClick={() => { navigate('/WalkIn'); }} variant="contained" color="primary">
          <span class="textblack">SIN RESERVA</span>
        </Button>

        <Button className="btnblanco circleRadius" onClick={deletedList} variant="contained" color="primary">
          <span class="textblack">RESERVAS ELIMINADAS</span>
        </Button>
        {!state.loading && !state.error && filterdReservations.length > 0 &&
          (<ExcelFile element={<button className="MuiButtonBase-root MuiButton-root MuiButton-contained btnblanco MuiButton-containedPrimary MuiButton-disableElevation circleRadius" tabindex="0"><span className="MuiButton-label"><span className="textblack">DESCARGAR RESERVAS</span></span><span className="MuiTouchRipple-root"></span></button>}>
            <ExcelSheet dataSet={multiDataSet2} name="Reservaciones">
            </ExcelSheet>
          </ExcelFile>)}
        <Button className="btnblanco circleRadius" onClick={openTotalDialog2} variant="contained" color="primary">
          <span class="textblack">CANTIDAD DE RESERVAS</span>
        </Button>
        <Button onClick={openFilterDialog} className="btnblanco circleRadius" variant="contained" color="primary">
          <span class="textblack">FILTRAR POR ESTATUS</span>
        </Button>
      </Box>
      <Hidden smUp>
        <Box className="arrowButtons">
          <Button onClick={() => scroll(-40)} className="arrowbutton" variant="contained" color="primary">
            <ArrowBackIosIcon />
          </Button>
          <Button onClick={() => scroll(+40)} className="arrowbutton" variant="contained" color="primary">
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Hidden>
      <Box
        component="div"
        className="w100"
      >
      </Box>
      <Box
        component="div"
        className="reservations__tabs-container dFlex flex-column align-center justify-between"
      >
        {state.loading && renderLoading()}
        {!state.loading && state.capacity.length > 0
          && state.reservationsBlocked.reservations.length === 0 && renderTab()}
        {(showingWaitingList || showingDeletedList) && renderWaitingTabs()}
        {!state.loading && filterdReservations.length > 0
          && state.reservationsBlocked.reservations.length === 0 && renderTable()}
        {!state.loading
          && state.reservationsBlocked.reservations.length === 0
          && state.reservations.length === 0
          && !state.error
          && renderNoReservation()}
        {!state.loading
          && state.reservationsBlocked.reservations.length > 0
          && !state.error && !showingWaitingList
          && renderReservationBlocked()}
        {!state.loading
          && state.reservationsBlocked.reservations.length > 0
          && !state.error && showingWaitingList
          && renderTable()}
        {!state.loading && state.error && renderError()}
        {!state.loading && !state.error && filterdReservations.length === 0 && state.reservations.length > 0 && !showingWaitingList && renderNoResults()}
        {!state.loading && !state.error && filterdReservations.length === 0 && showingWaitingList > 0 && renderNoPending()}
      </Box>
    </Container>
  </>
);
};

export default Reservations;
