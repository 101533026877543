import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import './RestaurantDescription.scss';
import * as CatalogsApi from '../../api/catalogs';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import DialogEditRestaurantDescription from '../DialogEditRestaurantDescription';
import DialogEditRestaurantKitchens from '../DialogEditRestaurantKitchens';
import DialogEditRestaurantCost from '../DialogEditRestaurantCost';
import DialogEditRestaurantSector from '../DialogEditRestaurantSector';

const RestaurantDescription = ({ setReload }) => {
  const { descriptionData } = useContext(RestaurantContext);
  const [fields, setFields] = useState({
    description: '',
    address: '',
    paymentMethod: '',
    latitude: '',
    longitude: '',
    phone: '',
    cheffName: '',
    cost: '',
    kitchen: [],
    sector: '',
  });

  const [extraServicesCatalog, setExtraServicesCatalog] = useState([]);
  const [extraServicesCheckbox, setExtraServicesCheckbox] = useState([]);
  const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogKitchens, setEditDialogKitchens] = useState(false);
  const [editDialogSector, setEditDialogSector] = useState(false);
  const [editDialogCost, setEditDialogCost] = useState(false);
  const [dialogData, setDialogData] = useState();
  const [sectorCatalogs] = useState([
    { id: 1, zone_id: '1', name: 'Naco' },
    { id: 2, zone_id: '1', name: 'Piantini' },
    { id: 5, zone_id: '1', name: 'Bella vista' },
    { id: 6, zone_id: '1', name: 'Evaristo Morales' },
    { id: 7, zone_id: '1', name: 'Arroyo Hondo Viejo' },
    { id: 8, zone_id: '1', name: 'Zona Colonial' },
    { id: 9, zone_id: '1', name: 'La caleta' },
    { id: 10, zone_id: '1', name: 'Galería 360' },
    { id: 11, zone_id: '1', name: 'La Esperilla' },
    { id: 12, zone_id: '1', name: 'Cuesta Hermosa' },
    { id: 13, zone_id: '1', name: 'Gazcue' },
    { id: 14, zone_id: '1', name: 'Paraiso' },
    { id: 15, zone_id: '1', name: 'Mirador Norte' },
    { id: 16, zone_id: '1', name: 'Serrallés' },
    { id: 17, zone_id: '1', name: 'San Isidro' },
    { id: 18, zone_id: '1', name: 'Mirador Sur' },
    { id: 19, zone_id: '1', name: 'Los cacicazgos' },
    { id: 20, zone_id: '1', name: 'Colina centro' },
    { id: 21, zone_id: '1', name: 'Mata hambre' },
    { id: 22, zone_id: '1', name: 'Ensanche Quisqueya' },
    { id: 23, zone_id: '1', name: 'Nuñez Cáceres' },
    { id: 24, zone_id: '1', name: 'Renacimiento' },
    { id: 25, zone_id: '1', name: 'Plaza Duarte' },
    { id: 26, zone_id: '1', name: 'Zona Oriental' },
    { id: 27, zone_id: '1', name: 'San Isidro' },
    { id: 28, zone_id: '1', name: 'Nuñez Caceres' },
    { id: 29, zone_id: '1', name: 'El Cacique' },
    { id: 30, zone_id: '1', name: 'El Millón' },
    { id: 31, zone_id: '1', name: 'Los Prados' },
  ]);

  useEffect(() => {
    const prepareKitchens = () => {
      const activeKitchens = [];
      descriptionData.kitchens.forEach((element) => {
        if (element.status === 1) {
          activeKitchens.push(element);
        }
      });
      return activeKitchens;
    };
    const fetchCatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogExtraService();
        if (isTruthy(success)) {
          setExtraServicesCatalog(data);
          const extraServicesArray = {};
          const keys = [];
          data.forEach((element) => {
            extraServicesArray[element.name] = element;
            extraServicesArray[element.name].status = false;
            keys.push(element.name);
          });
          if (descriptionData) {
            setFields({
              description: descriptionData.description !== undefined ? descriptionData.description : '',
              address: descriptionData.address !== undefined ? descriptionData.address : '',
              latitude: descriptionData.latitude !== undefined ? descriptionData.latitude : '',
              longitude: descriptionData.longitude !== undefined ? descriptionData.longitude : '',
              phone: descriptionData.phone !== undefined ? descriptionData.phone : '',
              cheffName: descriptionData.cheffName !== undefined ? descriptionData.cheffName : '',
              kitchen: descriptionData.kitchens !== undefined ? prepareKitchens() : [],
              cost: descriptionData.cost !== undefined ? descriptionData.cost.name : '',
              paymentMethod: descriptionData.paymentMethod !== undefined ? descriptionData.paymentMethod : '',
              sector: descriptionData.sector !== undefined ? descriptionData.sector : '',
            });
            if (descriptionData.extraServices.length > 0) {
              descriptionData.extraServices.forEach((element) => {
                // eslint-disable-next-line no-unneeded-ternary
                extraServicesArray[element.name].status = element.status ? true : false;
              });
            }
            const preparedExtraServices = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keys.length; index++) {
              const element = extraServicesArray[keys[index]];
              preparedExtraServices.push(element);
            }
            setExtraServicesCheckbox(preparedExtraServices);
          }
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchCatalogs();
  }, [descriptionData]);

  const handleExtraServiceSubmit = async (e, item) => {
    e.preventDefault();
    setCheckboxesDisabled(true);
    const body = {
      extra_service_eid: item.encryptId,
      status: !item.status,
    };
    try {
      const { success, message } = await RestaurantsApi.extraService(body);
      if (success) {
        const newCheckboxData = extraServicesCheckbox;
        newCheckboxData.forEach((element) => {
          if (element.name === item.name) {
            // eslint-disable-next-line no-param-reassign
            element.status = !item.status;
          }
        });
        setExtraServicesCheckbox(newCheckboxData);
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
      setCheckboxesDisabled(false);
    } catch (error) {
      console.log(error);
      setCheckboxesDisabled(false);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const openEditDialog = (value, field) => {
    setDialogData({ value, field });
    setEditDialog(true);
  };
  const closeEditDialog = () => {
    setEditDialog(false);
  };
  const openEditDialogSector = (value, field) => {
    setDialogData({ value, field });
    setEditDialogSector(true);
  };
  const closeEditDialogSector = () => {
    setEditDialogSector(false);
    setReload(true);
  };
  const openEditDialogKitchens = (value, field) => {
    setDialogData({ value, field });
    setEditDialogKitchens(true);
  };
  const closeEditDialogKitchens = () => {
    setEditDialogKitchens(false);
    setReload(true);
  };

  const openEditDialogCost = (value, field) => {
    setDialogData({ value, field });
    setEditDialogCost(true);
  };

  const closeEditDialogCost = () => {
    setEditDialogCost(false);
    setReload(true);
  };

  // Sends all restaurant/description updates
  const submitForm = async (data) => {
    const body = {};
    switch (data.field) {
      case 'description':
        body.description = data.value;
        break;
      case 'phone':
        body.phone = data.value;
        break;
      case 'cheffName':
        body.cheff_name = data.value;
        break;
      case 'paymentMethod':
        body.paymentMethod = data.value;
        break;
      case 'address':
        body.address = {
          address: data.value.address,
          latitude: Number(data.value.latitude),
          longitude: Number(data.value.longitude),
        };
        break;
      default:
        break;
    }
    try {
      const { success, message } = await RestaurantsApi.editDescription(body);
      console.log(success, message);
      if (success && success === 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        closeEditDialog();
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        console.log(message);
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
        closeEditDialog();
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      closeEditDialog();
    }
  };
  const printSectorName = (id) => {
    let sectorName = '';
    sectorCatalogs.forEach((element) => {
      console.log(element);
      if (element.id === id) {
        sectorName = element.name;
      }
    });
    return sectorName;
  };
  const renderView = () => (
    <>
      <DialogEditRestaurantDescription
        isOpen={editDialog}
        onYes={(data) => submitForm(data)}
        onNo={closeEditDialog}
        data={dialogData}
      />
      <DialogEditRestaurantSector
        isOpen={editDialogSector}
        onNo={closeEditDialogSector}
        data={dialogData}
      />
      <DialogEditRestaurantKitchens
        isOpen={editDialogKitchens}
        onNo={closeEditDialogKitchens}
        data={dialogData}
      />
      <DialogEditRestaurantCost
        isOpen={editDialogCost}
        onNo={closeEditDialogCost}
        data={dialogData}
      />
      <div className="restaurantDescription__restaurant-name mb-2">
        <Typography variant="h6">
          {descriptionData.name}
        </Typography>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Descripción
          </div>
          <div>
            {fields.description}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialog(fields.description, 'description'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Dirección
          </div>
          <div>
            {fields.address}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialog(fields.address, 'address'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Sector
          </div>
          <div>
            {printSectorName(fields.sector)}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialogSector(fields.sector, 'sector'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Teléfono
          </div>
          <div>
            {fields.phone}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialog(fields.phone, 'phone'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Nombre del chef
          </div>
          <div>
            {fields.cheffName}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialog(fields.cheffName, 'cheffName'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Tipo de comida
          </div>
          <div>
            {fields.kitchen.map((item, i) => {
              if (i + 1 < fields.kitchen.length) {
                return `${item.name}, `;
              }
              return `${item.name}.`;
            })}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialogKitchens(fields.kitchen, 'kitchen'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Metodo de pago
          </div>
          <div>
            {fields.paymentMethod}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialog(fields.paymentMethod, 'paymentMethod'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div className="restaurantDescription__field-group">
        <div className="restaurantDescription-form__field-container">
          <div className="color-grey">
            Rango de precio
          </div>
          <div className="bold">
            {fields.cost}
          </div>
        </div>
        <div className="restaurantDescription-form__buttonGroup">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { openEditDialogCost(fields.cost, 'cost'); }}
          >
            EDITAR
          </Button>
        </div>
      </div>
      <div>
        {checkboxesDisabled && (
          <CircularProgress color="primary" className="mb-2" />
        )}
        <div className="restaurantDescription__field-checkbox-group">
          {extraServicesCatalog && extraServicesCheckbox.map((item) => (
            (item.encryptId !== 'eyJpdiI6InN6MjdENzFqVzFQR0RsWkRwR3ZBaXc9PSIsInZhbHVlIjoiYkdzU3hcL1ZFTGRid2pDaWJSMmR4V2c9PSIsIm1hYyI6IjM0YjM1YmM2OTBhYmU0YWIyMWYzYjQzNzdmYjk2MDdmMjEyY2M0ZTNiNmJmYTM2MDg5NzQzZWNmODUxNWUzZDMifQ==' && item.encryptId !== 'eyJpdiI6ImdzbmRSMjlGTm5Ba0l6UGFWWUFuNWc9PSIsInZhbHVlIjoiUnRiS3YweE1NVFNrNTlFODVJQlpnUT09IiwibWFjIjoiNDYzZTFkMjYxNzYxYmIxYTUzZTFkODdmZjc4ZDBkYzhjMzBhOTA2Y2FhNGRhOGUzYTg2NDA3YzIyNjg5YzIxZiJ9')
              ? (
                <>
                  <FormControlLabel
                    key={uuid()}
                    label={item.name}
                    control={(
                      <Checkbox
                        checked={item.status}
                        name={item.name}
                        disableRipple
                        color="primary"
                        onChange={(e) => { handleExtraServiceSubmit(e, item); }}
                        disabled={checkboxesDisabled}
                      />
                    )}
                  />
                </>
              )
              : null
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Box
        component="div"
        className="dFlex flex-column w100"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Grid container>
          <Grid item xs={12} className="pl-1 pr-1 pb-3">
            {descriptionData && renderView()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantDescription.propTypes = {
  setReload: PropTypes.func,
};

RestaurantDescription.defaultProps = {
  setReload: () => { },
};

export default RestaurantDescription;
