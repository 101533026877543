import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
// import Clock from 'react-live-clock';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Drawer,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import moment from 'moment';
import TheMenuOptions from '../TheMenuOptions';
import { useAuth } from '../../context/auth-context';
// eslint-disable-next-line import/no-named-as-default
import TokenManager from '../../api/utils/TokenManager';
import dictionary from '../../utils/dictionary';
import * as CommentsApi from '../../api/restaurants/comments';

// import PartialNavLink from '../../router/PartialNavLink';
import 'moment/locale/es';

import Logo from '../../images/logo-svg2.svg';

import DialogChangePassword from '../DialogChangePassword';
import DialogLogout from '../DialogLogout';

import './TheMenu.scss';
// import { logout } from '../../api/auth';

const useStyles = makeStyles(() => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: 'black',
    color: 'white',
    height: '100vh',
    overflowY: 'hidden',
    width: '100%',
  },
}));
const TheMenu = () => {
  const { logout } = useAuth();
  const userRole = new TokenManager('role').getToken();
  const [response2, setResponse2] = useState(null);
  useEffect(() => {
    CommentsApi.showClientSurvey()
      .then((response) => {
        console.log('he');
        console.log(response.data.reviews);
        console.log('he');
        setResponse2(response.data.reviews);
      })
      .catch(() => {
        console.error('he');
      });
  }, []);
  console.log(response2);
  console.log('arriba');
  const handleLogoutClick = async () => {
    await logout();
    // eslint-disable-next-line no-undef
    window.location.reload(true);
  };
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  const [date] = useState({
    day: moment().locale('es').format('DD MMMM yyyy'),
    time: moment().format('hh:mm:ss'),
  });

  const navigateTo = (route) => {
    navigate(route);
    // alert(`navigate to ${route}`);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const openDialog = () => {
    setState({ ...state, top: false });
    setLogoutDialog(true);
  };
  const closeDialog = () => {
    setState({ ...state, top: false });
    setLogoutDialog(false);
  };

  const openChangePasswordDialog = () => {
    setState({ ...state, top: false });
    setChangePasswordDialog(true);
  };
  const closeChangePasswordDialog = () => {
    setState({ ...state, top: false });
    setChangePasswordDialog(false);
  };

  const menuOptions = [
    { key: 1, string: 'EDITAR CONTRASEÑA', action: () => { openChangePasswordDialog(); } },
    { key: 2, string: 'CERRAR SESIÓN', action: () => { openDialog(); } },
  ];

  return (
    <>
      <DialogLogout
        isOpen={logoutDialog}
        onYes={handleLogoutClick}
        onNo={closeDialog}
      />
      <DialogChangePassword
        isOpen={changePasswordDialog}
        onNo={closeChangePasswordDialog}
      />
      <AppBar className="the-menu" color="secondary" position="relative" elevation={0}>
        <Container className="the-menu__container">
          <Hidden smUp>
            <Box component="div" className="the-menu__mobile w100">
              <IconButton />
              <Typography
                onClick={toggleDrawer('top', true)}
                variant="h6"
                className="the-menu__mobile-text"
              >
                MENÚ
              </Typography>
              <Box
                component="div"
                className="the-menu__user-box"
              >
                <TheMenuOptions options={menuOptions} color="secondary" />
              </Box>
            </Box>
          </Hidden>
          <Hidden xsDown>
            <Toolbar className="the-menu__toolbar">
              <div className="dFlex flex-row align-center justify-start">
                {response2 !== 0 ? (
                  <span className="dot" style={{ color: '#ff3616' }}> </span>
                ) : null}
                <Hidden xsDown>
                  <IconButton
                    onClick={toggleDrawer('top', true)}
                    edge="start"
                    className="the-menu__burger-button"
                    color="inherit"
                    aria-label="menu"
                  >
                    <MenuIcon />
                  </IconButton>
                  <div className="the-menu__separator" />
                </Hidden>
                <Box component="div" className="the-menu-logo">
                  <img className="img-fluid" src={Logo} alt="Logo" />
                </Box>
              </div>
              <Hidden xsDown>
                {/* <Box
                component="div"
                className="the-menu__time-box"
              >
                <Typography
                  variant="body1"
                  className="the-menu-time"
                >
                  {date.day}
                </Typography>
                <div className="the-menu__separator" />
                <QueryBuilderIcon className="mr-1" />
                <Clock format="HH:mm:ss" ticking />
              </Box> */}
                <Box
                  component="div"
                  className="the-menu__user-box"
                >
                  <TheMenuOptions options={menuOptions} color="primary" />
                </Box>
              </Hidden>
            </Toolbar>
          </Hidden>
        </Container>

      </AppBar>
      <Drawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer('top', false)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Hidden xsDown>
          <AppBar className="the-menu white__separator" color="primary" position="relative" elevation={0}>
            <Container className="the-menu__container">
              <Toolbar className="the-menu__toolbar">
                <div className="dFlex flex-row align-center">
                  <Hidden xsDown>
                    <IconButton
                      onClick={toggleDrawer('top', false)}
                      edge="start"
                      className="the-menu__button"
                      color="inherit"
                      aria-label="menu"
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className="the-menu__separator white__separator" />
                  </Hidden>
                  <Box component="div" className="the-menu-logotwo" style={{ filter: 'invert(100%)' }}>
                    <img className="img-fluid" src={Logo} alt="Logo" />
                  </Box>
                </div>
                <div className="the-menu__time-box">
                  <Typography variant="body1" className="the-menu-time">
                    {date.day}
                  </Typography>
                  <div className="the-menu__separator white__separator" />
                  <QueryBuilderIcon className="mr-1" />
                  <Typography variant="body1" className="the-menu-time">
                    {date.time}
                  </Typography>
                </div>
              </Toolbar>
            </Container>
          </AppBar>
        </Hidden>
        <Grid container className="the-menu__open-menu-container" spacing={0}>
          <Grid item xs={12} className="dFlex flex-column align-center justify-center">
            <Box component="div">
              <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('/reservaciones'); }}>
                Reservaciones
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} className="dFlex flex-column align-center justify-center">
            <Box component="div">
              <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('/estadisticas'); }}>
                Estadísticas
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} className="dFlex flex-column align-center justify-center">
            <Box component="div">
              <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('/clientes'); }}>
                Clientes
              </button>
            </Box>
          </Grid>
          {/* {dictionary.userType[2] === userRole && (
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <button
                  className="the-menu__buttons font__commutter bold"
                  type="button"
                  onClick={() => { navigateTo('cobros'); }}
                >
                  Cobros
                </button>
              </Box>
            </Grid>
          )} */}
          {dictionary.userType[2] === userRole && (
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('/ofertas'); }}>
                  Experiencias
                </button>
              </Box>
            </Grid>
          )}
          {dictionary.userType[2] === userRole && (
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('/restaurante'); }}>
                  Info del restaurante
                </button>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} className="dFlex flex-column align-center justify-center">
            <Box component="div">
              <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigateTo('comentarios'); }}>
                {response2 !== 0 ? (
                  <span className="dot" style={{ color: '#ff3616' }}>....</span>
                ) : null}
                ENCUESTA A LOS CLIENTES
              </button>
            </Box>
          </Grid>
          {dictionary.userType[2] === userRole && (
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigate('staff'); }}>
                  Staff
                </button>
              </Box>
            </Grid>
          )}
          {dictionary.userType[2] === userRole && (
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <button className="the-menu__buttons font__commutter bold" type="button" onClick={() => { navigate('floormap'); }}>
                  Mapa
                </button>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} className="dFlex flex-column align-center justify-center">
            <Box component="div">
              <button
                className="the-menu__buttons font__commutter bold"
                type="button"
                onClick={() => openDialog()}
              >
                Cerrar Sesión
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} className="dFlex flex-column align-center justify-center mt-5">
            <Box component="div" className="font__roboto">
              Reserva Ya! © 2021
            </Box>
            <Box component="div" className="font__roboto">
              <button
                className="the-menu__buttons-legals"
                type="button"
                onClick={() => navigate('terms')}
              >
                Términos y condiciones
              </button>
              {' '}
              |
              <button
                className="the-menu__buttons-legals"
                type="button"
                onClick={() => navigate('privacy')}
              >
                Aviso de privacidad
              </button>
            </Box>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <IconButton
                  onClick={toggleDrawer('top', false)}
                  edge="start"
                  className="the-menu__button"
                  color="inherit"
                  aria-label="menu"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Drawer>

    </>
  );
};

export default TheMenu;
