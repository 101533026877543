import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Modal,
  Typography,
} from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import SnackbarAlert from '../SnackbarAlert';

import * as RestaurantsApi from '../../api/restaurants/restaurants';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import isTruthy from '../../utils/isTruthy';
import websiteRegex from '../../utils/websiteRegex';
import emojiRegex from '../../utils/emojiRegex';

import './RestaurantMenu.scss';

const RestaurantMenu = ({ setReload }) => {
  // const [value, setValue] = useState(0);
  const { menuData, linkData, specialmenuData } = useContext(RestaurantContext);
  const [fields, setFields] = useState({ menu: '', link: '', specialmenu: '' });

  const [validations, setValidations] = useState({
    menu: true,
    link: true,
    specialmenu: true,

  });

  // Modal texto no se pudo reservar
  const [modalUrlsDeMenus, setModalUrlsDeMenus] = useState(false);

  const cerrarModalUrlsDeMenus = () => {
    setModalUrlsDeMenus(false);
  };

  const [menuUrls, setMenuUrls] = useState({
    urlMenuSanValentin: '',
    urlMenuDiaMadre: '',
    urlMenuDiaPadre: '',
    urlMenuNavidad: '',
    urlMenuAnoNuevo: '',
    urlMenu31Diciembre: '',
    urlMenuRestaurantWeek: '',
    urlMenuEventos: '',
    urlMenuEjecutivo: '',
    urlMenuBebidas: '',
    urlMenuCocteles: '',
    urlMenuVinos: '',
    urlMenuDegustacion: '',
    urlMenuExclusivo: '',
    urlMenuGustazos: '',
    urlMenuMusicaEnVivo: '',
    urlMenuSalonesPrivados: '',
    urlMenuSalonPrivado: '',
  });

  const [hasEditedField, setHasEditedField] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    setFields({ menu: menuData !== null && menuData.length > 0 ? menuData : '', link: linkData !== null && linkData.length > 0 ? linkData : '', specialmenu: specialmenuData });
  }, [menuData, linkData, specialmenuData]);

  const handleValidations = (field, value) => {
    const mapValidations = {
      menu: (menu) => websiteRegex.test(menu),
      link: () => true,
      specialmenu: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    setHasEditedField(true);
    handleValidations(field, cleanString);
  };

  const updateMenus = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...menuUrls,
    };
    newFields[field] = cleanString;
    setMenuUrls(newFields);
  };

  const obtenerMenus = async () => {
    try {
      const data = await RestaurantsApi.getMenus();
      if ('id' in data) {
        const {
          createdAt, id, restaurantId, updatedAt, ...menus
        } = data;
        setMenuUrls(menus);
        setModalUrlsDeMenus(true);
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Menus Cargados',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'No se pudieron obtener los menus',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  const guardarMenus = async () => {
    try {
      const { message } = await RestaurantsApi.updateMenus({
        url_menu_san_valentin: websiteRegex.test(menuUrls.urlMenuSanValentin) ? menuUrls.urlMenuSanValentin : '',
        url_menu_dia_madre: websiteRegex.test(menuUrls.urlMenuDiaMadre) ? menuUrls.urlMenuDiaMadre : '',
        url_menu_dia_padre: websiteRegex.test(menuUrls.urlMenuDiaPadre) ? menuUrls.urlMenuDiaPadre : '',
        url_menu_navidad: websiteRegex.test(menuUrls.urlMenuNavidad) ? menuUrls.urlMenuNavidad : '',
        url_menu_ano_nuevo: websiteRegex.test(menuUrls.urlMenuAnoNuevo) ? menuUrls.urlMenuAnoNuevo : '',
        url_menu_31_diciembre: websiteRegex.test(menuUrls.urlMenu31Diciembre) ? menuUrls.urlMenu31Diciembre : '',
        url_menu_restaurant_week: websiteRegex.test(menuUrls.urlMenuRestaurantWeek) ? menuUrls.urlMenuRestaurantWeek : '',
        url_menu_eventos: websiteRegex.test(menuUrls.urlMenuEventos) ? menuUrls.urlMenuEventos : '',
        url_menu_ejecutivo: websiteRegex.test(menuUrls.urlMenuEjecutivo) ? menuUrls.urlMenuEjecutivo : '',
        url_menu_bebidas: websiteRegex.test(menuUrls.urlMenuBebidas) ? menuUrls.urlMenuBebidas : '',
        url_menu_cocteles: websiteRegex.test(menuUrls.urlMenuCocteles) ? menuUrls.urlMenuCocteles : '',
        url_menu_vinos: websiteRegex.test(menuUrls.urlMenuVinos) ? menuUrls.urlMenuVinos : '',
        url_menu_degustacion: websiteRegex.test(menuUrls.urlMenuDegustacion) ? menuUrls.urlMenuDegustacion : '',
        url_menu_exclusivo: websiteRegex.test(menuUrls.urlMenuExclusivo) ? menuUrls.urlMenuExclusivo : '',
        url_menu_gustazos: websiteRegex.test(menuUrls.urlMenuGustazos) ? menuUrls.urlMenuGustazos : '',
        url_menu_musica_en_vivo: websiteRegex.test(menuUrls.urlMenuMusicaEnVivo) ? menuUrls.urlMenuMusicaEnVivo : '',
        url_menu_salones_privados: websiteRegex.test(menuUrls.urlMenuSalonesPrivados) ? menuUrls.urlMenuSalonesPrivados : '',
        url_menu_salon_privado: websiteRegex.test(menuUrls.urlMenuSalonPrivado) ? menuUrls.urlMenuSalonPrivado : '',
      });
      if (message === 'Menus updated successfully.') {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Menús actualizados correctamente',
        });
        setTimeout(() => {
          setReload(true);
        }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'Los menús no pudieron ser actualizados correctamente',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations.menu) {
      try {
        const { success, message } = await RestaurantsApi.editMenu({
          menu_url: fields.menu,
          link_url: fields.link,
          specialmenu_url: fields.specialmenu,

        });
        if (isTruthy(success)) {
          setSnackbar({
            open: true,
            severity: 'success',
            text: message,
          });
          setTimeout(() => {
            setReload(true);
          }, 1500);
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.toJSON().message,
        });
      }
    }
  };

  const renderForm = () => (
    <form onSubmit={validations.menu && hasEditedField && handleSubmit} className="w100 dFlex flex-column align-center justify-center" autoComplete="off">
      <h3>Link del menú</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.menu}
          id="menu"
          onChange={(e) => updateFields('menu', e.target.value)}
          value={fields.menu}
        />
      </div>
      <h3 className="mt-2">Link de los salones de eventos</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.link}
          id="link"
          onChange={(e) => updateFields('link', e.target.value)}
          value={fields.link}
        />
      </div>
      <h3 className="mt-2">Link del menú especial (Navidad, Restaurant Week, etc.)</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.specialmenu}
          id="specialmenu"
          onChange={(e) => updateFields('specialmenu', e.target.value)}
          value={fields.specialmenu}
        />
      </div>
      <div className="restaurantMenu-form__buttonGroup mt-2">
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          ACTUALIZAR MENÚ
        </Button>
      </div>
      {/* Menus nuevos */}
      <Modal
        keepMounted
        open={modalUrlsDeMenus}
        onClose={cerrarModalUrlsDeMenus}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal__box3 pl-1 pb-1 pt-1 pr-1">
          <div className="dFlex flex-column align-end w100 mb-1">
            <IconButton
              variant="outlined"
              className="floormap-icon"
              style={{ background: 'black' }}
              onClick={cerrarModalUrlsDeMenus}
            >
              <Typography style={{ color: 'white' }}>X</Typography>
            </IconButton>
          </div>
          <div style={{ overflowY: 'scroll', height: '20rem', width: '20rem' }}>
            <h3 className="mt-2 text-center w90">Link menú San Valentín</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuSanValentin) || menuUrls.urlMenuSanValentin === '')}
                onChange={(e) => updateMenus('urlMenuSanValentin', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuSanValentin}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Día de la Madre</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuDiaMadre) || menuUrls.urlMenuDiaMadre === '')}
                onChange={(e) => updateMenus('urlMenuDiaMadre', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuDiaMadre}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Día del Padre</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuDiaPadre) || menuUrls.urlMenuDiaPadre === '')}
                onChange={(e) => updateMenus('urlMenuDiaPadre', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuDiaPadre}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú De Navidad</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuNavidad) || menuUrls.urlMenuNavidad === '')}
                onChange={(e) => updateMenus('urlMenuNavidad', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuNavidad}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú De Año Nuevo</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuAnoNuevo) || menuUrls.urlMenuAnoNuevo === '')}
                onChange={(e) => updateMenus('urlMenuAnoNuevo', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuAnoNuevo}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú 31 de Diciembre</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenu31Diciembre) || menuUrls.urlMenu31Diciembre === '')}
                onChange={(e) => updateMenus('urlMenu31Diciembre', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenu31Diciembre}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Restaurante Week</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuRestaurantWeek) || menuUrls.urlMenuRestaurantWeek === '')}
                onChange={(e) => updateMenus('urlMenuRestaurantWeek', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuRestaurantWeek}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Eventos</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuEventos) || menuUrls.urlMenuEventos === '')}
                onChange={(e) => updateMenus('urlMenuEventos', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuEventos}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Ejecutivo</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuEjecutivo) || menuUrls.urlMenuEjecutivo === '')}
                onChange={(e) => updateMenus('urlMenuEjecutivo', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuEjecutivo}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Bebidas</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuBebidas) || menuUrls.urlMenuBebidas === '')}
                onChange={(e) => updateMenus('urlMenuBebidas', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuBebidas}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Cocteles</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuCocteles) || menuUrls.urlMenuCocteles === '')}
                onChange={(e) => updateMenus('urlMenuCocteles', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuCocteles}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Vinos</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuVinos) || menuUrls.urlMenuVinos === '')}
                onChange={(e) => updateMenus('urlMenuVinos', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuVinos}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Desgustación</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuDegustacion) || menuUrls.urlMenuDegustacion === '')}
                onChange={(e) => updateMenus('urlMenuDegustacion', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuDegustacion}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Exclusivo</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuExclusivo) || menuUrls.urlMenuExclusivo === '')}
                onChange={(e) => updateMenus('urlMenuExclusivo', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuExclusivo}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Gustazos</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuGustazos) || menuUrls.urlMenuGustazos === '')}
                onChange={(e) => updateMenus('urlMenuGustazos', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuGustazos}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Musica en Vivo</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuMusicaEnVivo) || menuUrls.urlMenuMusicaEnVivo === '')}
                onChange={(e) => updateMenus('urlMenuMusicaEnVivo', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuMusicaEnVivo}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Salones Privados</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuSalonesPrivados) || menuUrls.urlMenuSalonesPrivados === '')}
                onChange={(e) => updateMenus('urlMenuSalonesPrivados', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuSalonesPrivados}
              />
            </div>

            <h3 className="mt-2 text-center w90">Link menú Salón Privado</h3>
            <div className="restaurantMenu-form__field-container w90">
              <TextField
                error={!(websiteRegex.test(menuUrls.urlMenuSalonPrivado) || menuUrls.urlMenuSalonPrivado === '')}
                onChange={(e) => updateMenus('urlMenuSalonPrivado', e.target.value)}
                className="border-bottom-lite w100"
                value={menuUrls.urlMenuSalonPrivado}
              />
            </div>
          </div>
          <div className="restaurantMenu-form__buttonGroup mt-2">
            <Button
              variant="contained"
              color="primary"
              onClick={guardarMenus}
            >
              GUARDAR MENÚS
            </Button>
          </div>
        </Box>
      </Modal>
      <div className="restaurantMenu-form__buttonGroup mt-2 mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={obtenerMenus}
        >
          MENúS OPCIONALES
        </Button>
      </div>
      {/* Fin menus nuevos */}
    </form>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            {renderForm()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantMenu.propTypes = {
  setReload: PropTypes.func,
};

RestaurantMenu.defaultProps = {
  setReload: () => { },
};

export default RestaurantMenu;
